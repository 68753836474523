import React from "react"

import { sanitize } from "dompurify"
import * as StorageAPI from "lib/core/storage.api"
import {
  getCurrentSchoolYear,
  isWithinSchoolYearStartPeriod,
} from "lib/leeruniek/time-helpers"
import ModalNotification from "components/modal-notification/modal-notification"
import { t } from "./new-school-year-notification.i18n"
import { connect } from "react-redux"

export const STORAGE_KEY = "hideNewSchoolYearNotificationForSchoolYear"

interface NewSchoolYearNotificationProps {
  isSchoolSuperuser: boolean
}

const NewSchoolYearNotification = ({
  isSchoolSuperuser,
}: NewSchoolYearNotificationProps) => {
  const valueFromStorage = StorageAPI.find(STORAGE_KEY)
  const [isOpen, setIsOpen] = React.useState(
    isSchoolSuperuser &&
      isWithinSchoolYearStartPeriod() &&
      !(
        valueFromStorage && Number(valueFromStorage) === getCurrentSchoolYear()
      ),
  )

  const handleClose = (shouldHideNotification: boolean) => {
    if (shouldHideNotification) {
      StorageAPI.upsert(
        {
          key: STORAGE_KEY,
          value: getCurrentSchoolYear(),
        },
        { local: true },
      )
    }
    setIsOpen(false)
  }

  return isOpen ? (
    <ModalNotification
      open
      title={t("new-schoolyear")}
      onClose={handleClose}
      hidable>
      <span
        dangerouslySetInnerHTML={{
          __html: sanitize(t("new-school-year-notification")),
        }}
      />
    </ModalNotification>
  ) : null
}

export default connect(
  (state: { school: { school: { isSchoolSuperuser: boolean } } }) => ({
    isSchoolSuperuser: state.school.school.isSchoolSuperuser,
  }),
)(NewSchoolYearNotification)
