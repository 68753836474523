import { translateWith } from "lib/core/i18n/i18n.utils"
import { languagePack as rootLanguagePack } from "lib/core/i18n/root.i18"

export const languagePack = {
  ["disconnected-notification"]: {
    nl: `School <b>{{name}}</b> has been disconnected`,
    en: `School <b>{{name}}</b> has been disconnected`,
  },

  ["connection-inactive"]: {
    nl: "Connection inactive",
    en: "Connection inactive",
  },
}

export const t = translateWith(languagePack, rootLanguagePack)
