import React from "react"

import { sanitize } from "dompurify"
import { connect } from "react-redux"
import * as StorageAPI from "lib/core/storage.api"
import ModalNotification from "components/modal-notification/modal-notification"
import { t } from "./school-disconnected-notification.i18n.js"

export const STORAGE_KEY = "hideSchoolDisconnectedNotification"

interface SchoolDisconnectedNotificationProps {
  school: { id?: number; isConnectionActive?: boolean; name?: string }
}

const SchoolDisconnectedNotification = ({
  school,
}: SchoolDisconnectedNotificationProps) => {
  const valueFromStorage = StorageAPI.find(STORAGE_KEY)
  const schoolIdsFromStorage = valueFromStorage
    ? JSON.parse(valueFromStorage)
    : []
  const isNotificationHiddenForSchool = schoolIdsFromStorage.includes(school.id)
  const [isOpen, setIsOpen] = React.useState(
    school.isConnectionActive === false && !isNotificationHiddenForSchool,
  )

  const handleClose = (shouldHideNotification: boolean) => {
    if (shouldHideNotification) {
      StorageAPI.upsert(
        {
          key: STORAGE_KEY,
          value: JSON.stringify([...schoolIdsFromStorage, school.id]),
        },
        { local: true },
      )
    }
    setIsOpen(false)
  }

  return isOpen ? (
    <ModalNotification
      open
      title={t("connection-inactive")}
      onClose={handleClose}
      hidable>
      <span
        dangerouslySetInnerHTML={{
          __html: sanitize(
            t("disconnected-notification", { name: school.name }),
          ),
        }}
      />
    </ModalNotification>
  ) : null
}

export default connect(
  (state: {
    school: {
      school: { id?: number; isConnectionActive?: boolean; name?: string }
    }
  }) => ({
    school: state.school.school,
  }),
)(SchoolDisconnectedNotification)
