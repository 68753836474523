import React from "react"

import MLUButton, { MLUButtonSize } from "components/ui/mlubutton/mlubutton"
import MLUDialog, {
  ActionsAlignment,
  DialogSize,
} from "components/ui/mludialog/mludialog"
import { LUCheckbox } from "webclient-ui/components"
import css from "./modal-notification.module.scss"
import { t } from "./modal-notification.i18n.js"

interface ModalNotificationProps {
  open: boolean
  title: string
  children: React.ReactNode
  hidable?: boolean
  onClose?: (hideNotification: boolean) => void
}

const ModalNotification = ({
  children,
  onClose,
  hidable = false,
  open,
  title,
}: ModalNotificationProps) => {
  const [hideNotification, setHideNotification] = React.useState(false)

  const handleClose = () => {
    if (onClose) {
      onClose(hideNotification)
    }
  }

  const handleHideNotification = () => {
    setHideNotification((prevHideNotification) => !prevHideNotification)
  }

  return (
    <MLUDialog
      open={open}
      className={css["notification-container"]}
      color="yellow"
      size={DialogSize.SMALL}
      title={title}
      actions={[
        <MLUButton
          key="btn-close"
          label={t("close")}
          size={MLUButtonSize.SMALL}
          onClick={handleClose}
        />,
      ]}
      actionsAlignment={ActionsAlignment.CENTER}>
      {children}
      {hidable && (
        <LUCheckbox
          label={t("hide-notification")}
          size="small"
          isChecked={hideNotification}
          onChange={handleHideNotification}
        />
      )}
    </MLUDialog>
  )
}

export default ModalNotification
